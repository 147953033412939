<template>
  <b-container fluid="md" class="px-0">
    <b-overlay :show="loading" rounded="sm">
      <h1>SSL Certificate</h1>

      <div class="d-flex flex-wrap">
        <b-form-group
          :class="css.textarea.group"
          :style="style.textarea.group"
          label="Certificate"
        >
          <b-textarea
            :class="css.textarea.input"
            :style="style.textarea.input"
            v-model="certificate.ssl.content"
            :rows="rows"
          ></b-textarea>
        </b-form-group>

        <b-form-group
          :class="css.textarea.group"
          :style="style.textarea.group"
          label="Private Key"
        >
          <b-textarea
            :class="css.textarea.input"
            :style="style.textarea.input"
            v-model="certificate.ssl.privateKey"
            :rows="rows"
          ></b-textarea>
        </b-form-group>
      </div>

      <div class="m-3 rounded bg-light">
        <div>
          <span class="font-weight-bold mr-1">Name:</span>
          <span>{{ certificate.name }}</span>
        </div>

        <div>
          <span class="font-weight-bold mr-1">Issuer:</span>
          <span>{{ certificate.issuer }}</span>
        </div>

        <div>
          <span class="font-weight-bold mr-1">Created:</span>
          <span>{{ certificate.creationDate }}</span>
        </div>

        <div>
          <span class="font-weight-bold mr-1">Expiration:</span>
          <span>{{ certificate.expirationDate }}</span>
          <b-badge pill :variant="remainingDaysVariant" class="ml-1"
            >{{ certificate.remainingDays }} days</b-badge
          >
        </div>
      </div>

      <div class="my-3">
        <b-button variant="primary" @click="downloadCertificate()" class="m-2"
          ><i class="fas fa-download mx-1"></i
          ><span class="mx-1">Download .pfx certificate file</span></b-button
        >

        <b-dropdown variant="warning" class="m-2" right>
          <template #button-content>
            <i class="fas fa-bolt"></i
            ><span class="mx-1"><strong>Update</strong> certificate in...</span>
          </template>
          <b-dropdown-item active @click="updateCertificates()"
            >All servers</b-dropdown-item
          >
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            @click="updateCertificates(server)"
            v-for="server of serversFiltered"
            :key="server.id"
            >{{ server.name }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </b-overlay>
  </b-container>
</template>

<script>
import { mapState } from "vuex";

import Service from "@/service";
import Utils from "@/utils";

export default {
  data() {
    return {
      loading: true,
      certificate: {},
      password: "",
      rows: 20,
      style: {
        textarea: {
          group: "flex: 1 1 25rem;",
          input: "font-size: 70%;",
        },
      },
      css: {
        textarea: {
          group: "m-1 flex-grow-1",
        },
      },
    };
  },

  computed: {
    ...mapState(["servers"]),

    serversFiltered() {
      return this.servers.filter((x) => x.hasDashboard);
    },

    sslContent() {
      return this.certificate.ssl?.content;
    },

    sslPrivateKey() {
      return this.certificate.ssl?.privateKey;
    },

    remainingDaysVariant() {
      const days = this.certificate.remainingDays;

      if (days < 7) return "danger";
      if (days < 30) return "warning";

      return "success";
    },

    certificateDownloadUrl() {
      let passwordPart = "";

      if (this.password) {
        passwordPart = `?password=${this.password}`;
      }

      return `${Service.certificateDownloadUrl}${passwordPart}`;
    },
  },

  methods: {
    promptPassword() {
      this.password = prompt(
        'Please, set a password for the certificate (otherwise default will be "taitus")'
      );

      return true;
    },

    async updateCertificates(server) {
      if (
        !confirm(
          "This will install and replace the current SSL certificate in all configured servers, do you want to proceed?"
        )
      )
        return;

      this.loading = true;

      try {
        const params = {
          content: this.sslContent,
          privateKey: this.sslPrivateKey,
          serverId: server.id,
        };
        const result = await Service.updateCertificates(params);

        if (result.success) {
          Utils.toastOk(this, "Update successful");
        } else {
          Utils.toaskError(this, result.message);
        }
      } finally {
        this.loading = false;
      }
    },

    async downloadCertificate() {
      this.promptPassword();

      try {
        const params = {
          content: this.sslContent,
          privateKey: this.sslPrivateKey,
          password: this.password,
        };

        const result = await Service.downloadCertificate(params);

        console.log(result);
      } finally {
        this.loading = false;
      }
    },

    async load() {
      this.loading = true;

      try {
        let result = await Service.getSsl();

        if (!result.success) {
          return Utils.toaskError(this, result.message);
        }

        this.certificate = result.data;

        if (!this.certificate.ssl) {
          this.certificate.ssl = { content: null, privateKey: null };
        }

        console.log("certificate", this.certificate);
      } finally {
        this.loading = false;
      }
    },
  },

  async mounted() {
    await this.load();
  },
};
</script>